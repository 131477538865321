import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import GAuth from "vue-google-oauth2";

const gauthOption = {
  clientId:
    "121878837722-gs0omauetf82js76bt08lici81614fl9.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "consent",
  fetch_basic_profile: true,
};
Vue.use(GAuth, gauthOption);

declare module "vue/types/vue" {
  // 3. Declare augmentation for Vue
  interface Vue {
    $gAuth: any;
  }
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
